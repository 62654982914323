<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
    <div>
        <!-- <img src="../assets/imgs/logo.png" style="width: 170px;height: 55px;display: block; margin: auto;" alt=""> -->
        <h4 style="text-align: center;margin-top: 20px;">{{ list.title }}</h4>
        <div style="text-align: center;">
            <div style="display: inline-block;" v-for="(item, index) in list.tags.split(',')" :key="index">
                <a-tag color="#108ee9">
                    {{ item }}
                </a-tag>
            </div>
        </div>
        <p style="text-align: center;margin-top: 10px;">{{ (list.updatetime).substring(0,10) }} </p>

        <div style="width: 60%;margin: auto;" v-html="list.content">
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
    components: {},
    data() {
        return {
            data: '',
            list: ''

        }
    },
    computed: {
        ...mapGetters("user", ["token"])
    },
    created() {
        this.data = this.$route.query.data
        this.show()
    },
    methods: {
        // 获取AI类型
        show() {
            this.$http("drawing.show", { id: this.data }).then(res => {
                if (res.code === 1) {
                    this.list = res.data
                }
            })
        },
    }
}
</script>

